import AudioMessage from './AudioMessage';
import CustomMessage from './CustomMessage';
import ImageMessage from './ImageMessage';
import StoryMessage from './StoryMessage';
import TextMessage from './TextMessage';
import VideoMessage from './VideoMessage';

export default function MessageComponent({ message }) {
  if (message.message_type === 'text') {
    return <TextMessage {...message} />;
  }

  if (!message.message_type || message.message_type === 'unsupported_type') {
    return <CustomMessage {...message} customMessage={'Unsupported Message'} />;
  }

  if (message.media_url) {
    switch (message.message_type) {
      case 'audio':
        return <AudioMessage {...message} />;
      case 'video':
        return <VideoMessage {...message} />;
      case 'image':
        return <ImageMessage {...message} />;
      case 'story_mention':
        return <StoryMessage {...message} />;
      default:
        return null;
    }
  }

  return <CustomMessage {...message} customMessage={'Message Not Available'} />;
}
